@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

.font-mono {
  font-family: 'Space Mono', monospace;
}

@keyframes pulse {
  0%, 100% { opacity: 0.2; }
  50% { opacity: 0.4; }
}

.animate-pulse {
  animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.delay-1000 {
  animation-delay: 1s;
}

@keyframes zoom-in {
  from { transform: scale(0.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.zoom-in { animation: zoom-in 0.3s ease-out; }

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.animated-border {
  position: relative;
  z-index: 0;
}

.animated-border::before {
  content: '';
  position: absolute;
  z-index: -2;
  inset: -1px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
  animation: rotate 3s linear infinite;
}

.animated-border::after {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(16px);
  border-radius: 8px;
}